import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = ({ data }) => (
  <Layout>
    {console.log("%c Drupal src: " + process.env.GATSBY_DRUPAL_BASE_URL, 'color: green;')}
    <Helmet>
      {/* <!-- Primary Meta Tags --> */}
      <meta name="title" content="My Awesome Website" />
      <meta name="author" content="Windwalker Group & Cahillswift" />
      <meta property="og:url" content="http://www.clearinghousenavigator.com/" />
      <meta property="og:site_name" content="Clearinghouse Navigator" />
      <script dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var
          f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PDVRZH6');</script>`
            }}
        />
    </Helmet> 

    <SEO 
      title="Clearinghouse Navigator - FMCSA's Drug and Alcohol Clearinghouse Training" 
      description="eLearning instructing FMCSA-regulated employers how to comply with FMCSA's clearinghouse drug and alcohol testing rule, 49 CFR Part 382"
    />
    <div dangerouslySetInnerHTML={{ __html: data.home.body.value }} />
    <div dangerouslySetInnerHTML={{ __html: data.timeline.body.value }} />
    <div dangerouslySetInnerHTML={{ __html: data.home_faq.body.value }} />
  </Layout>
)

export const query = graphql` 
  query {
    timeline: nodePage(title: {eq: "Timeline"}) {
      title
      body {
        value
      }
      id
    }
    home: nodePage(title: {eq: "Home"}) {
      title
      body {
        value
      }
      id
    }
    home_faq: nodePage(title: {eq: "Home FAQ Summary"}) {
      title
      body {
        value
      }
      id
    }
  } `
export default IndexPage
